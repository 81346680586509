@import 'scss/vars/_colors';
@import 'scss/base/fonts';

@import './NewsDetailsContentDesktop';
@import './NewsDetailsContentMobile';

.news-details-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .news-details-stats {
    display: flex;
    justify-self: flex-end;
    gap: 16px;
  }

  .news-details-metadata-container {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight-book;
    color: $secondary-info-color;
    justify-content: space-between;
  }

  .news-details-themes-and-date {
    display: flex;
  }

  .news-details-publication-date {
    white-space: nowrap;
  }

  .news-details-thumbnail-container {
    display: flex;
    width: 100%;
    max-width: 750px;
    align-items: center;
    justify-content: center;
  }

  .news-details-news-content {
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight-book;
    color: $label-color;

    h1,
    h2 {
      line-height: 125%;
    }

    blockquote {
      margin-block: 1em;
      margin-inline: 2em;
      padding: 0.5em 1em;
      border-left: $title-color solid 3px;
    }

    // Handling indents set by Quill editor
    .ql-indent-1 {
      padding-left: 3em;
    }
    .ql-indent-2 {
      padding-left: 6em;
    }
    .ql-indent-3 {
      padding-left: 9em;
    }
    .ql-indent-4 {
      padding-left: 12em;
    }
    .ql-indent-5 {
      padding-left: 15em;
    }
    .ql-indent-6 {
      padding-left: 18em;
    }
    .ql-indent-7 {
      padding-left: 21em;
    }
    .ql-indent-8 {
      padding-left: 24em;
    }
  }