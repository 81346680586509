/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.add-app-modal-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 47%;
  top: 12%;
  left: 26.5%;
  background-color: #ffffff;
  outline: 0;
  border-radius: 16px; }

.add-app-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #e5e5e5; }
  .add-app-modal-header .custom-button {
    padding: 5px; }
