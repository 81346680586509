/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.add-app-modal-tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width: 1px;
  color: #3e3d42;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 3px; }
  .add-app-modal-tab-container:hover {
    background-color: rgba(0, 0, 0, 0.08); }

.add-app-modal-tab-container-selected {
  border-bottom: 4px solid #fdea4f;
  padding-bottom: 0; }
