/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.view-count-container {
  display: flex;
  gap: 4px;
  align-items: center; }

.view-count-value {
  font-weight: 350;
  color: #929292; }

.view-count-icon {
  color: #929292; }
