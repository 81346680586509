.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  /*

    These are base style, additional styles will be included in other components

     */ }
  .modal-container .modal-content {
    position: absolute;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4); }
