/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.go-accessibility-link-container {
  text-decoration: unset; }

.go-accessibility-container {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #2c4965;
  fill: #2c4965; }
  .go-accessibility-container .go-accessibility-icon {
    display: flex;
    align-items: center; }
    .go-accessibility-container .go-accessibility-icon img {
      width: 1.25rem;
      height: 1.25rem; }
  .go-accessibility-container:hover {
    background-color: transparent;
    filter: brightness(0.92); }
