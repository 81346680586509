/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.theme-filter-item-container {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #fdea4f;
  display: flex;
  align-items: center;
  justify-content: center; }
  .theme-filter-item-container .theme-filter-item-content {
    padding: 6px 12px;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    color: #3e3d42;
    font-weight: 350;
    transition: 0.2s ease-in-out; }
    .theme-filter-item-container .theme-filter-item-content:hover {
      background-color: rgba(0, 0, 0, 0.08); }

.selected-theme-filter-item {
  background-color: #fdea4f; }
  .selected-theme-filter-item .theme-filter-item-content {
    font-weight: 700; }
