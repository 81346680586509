@import 'scss/base/utils';

@include min-width(m) {
  .search-news-modal-container {
    top: 24px;
    left: 24px;
    height: calc(100% - 48px);
    width: calc(100% - 48px);
    border-radius: 16px;

    .header {
      padding: 24px;
    }

    .content {
      padding: 24px;
    }

    .results-total {
      align-items: center;
    }

    .results-container {
      gap: 16px;
    }

    .result {
      margin-right: 20px;
    }

    .search-loader-block {
      .search-loader-container {
        width: 10%;
        height: 10%;
      }
    }
  }
}
