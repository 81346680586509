/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.news-details-media-modal-container {
  position: fixed;
  top: 84px;
  left: 24px;
  height: calc(100% - 168px);
  min-height: 160px;
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: white; }
  .news-details-media-modal-container .media-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px; }
  .news-details-media-modal-container .media-modal-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #3e3d42;
    text-transform: uppercase; }
  .news-details-media-modal-container .media-modal-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
    .news-details-media-modal-container .media-modal-content .media-modal-embedded-viewer {
      border: none; }
  .news-details-media-modal-container .media-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px; }
