.accessibility-container {
  display: flex;
  flex-direction: column;
  gap: 1rem; }
  .accessibility-container p,
  .accessibility-container li {
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0.5rem; }
