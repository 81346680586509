/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.tool-link-container {
  border: 1px solid #fdea4f;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.2s ease-in-out; }
  .tool-link-container.global-tool-link {
    border-color: #53c0df; }
  .tool-link-container:hover {
    background-color: rgba(0, 0, 0, 0.08); }
  .tool-link-container .tool-link {
    display: flex;
    flex-grow: 1;
    gap: 7px;
    align-items: center;
    padding: 8px 15px 8px 12px;
    color: #3e3d42;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%; }
    .tool-link-container .tool-link svg {
      width: 21px;
      height: 21px;
      vertical-align: auto;
      margin-left: auto; }
