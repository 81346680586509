/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.frontoffice-home-tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 11px;
  flex-grow: 1;
  width: 1px;
  height: 100%;
  color: #3e3d42;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-right: 1px solid #f3f4f6; }

.frontoffice-home-tab-container-selected {
  color: #53c0df; }
