/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.available-custom-tool-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.available-custom-tool-row-content {
  display: flex;
  gap: 16px;
  align-items: center; }

.available-custom-tool-row-button-icon {
  color: #3e3d42; }

.available-custom-tool-row-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #3e3d42; }
