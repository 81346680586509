/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.available-tool-carron-container {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #009e60; }

.available-tool-carron-icon {
  font-size: 24px;
  line-height: 24px;
  font-weight: 350;
  color: #ffffff; }
