.header-link,
.header-button {
  height: 48px;
  width: 48px;
  background-color: #f3f4f6;
  border-radius: 8px;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden; }

.header-button {
  height: 40px;
  flex: 0 0 40px; }

.header-link .header-link-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out; }
  .header-link .header-link-content:hover {
    background-color: rgba(0, 0, 0, 0.08); }
