/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.snackbar-container {
  position: fixed;
  top: 24px;
  left: 2.5vw;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95vw;
  height: 72px;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: -8px 8px 32px -8px rgba(58, 9, 122, 0.16); }

.snackbar-text {
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700; }

.snackbar-button {
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.32);
  border-radius: 8px; }
  .snackbar-button .snackbar-button-content {
    width: 100%;
    height: 100%;
    padding: 10px 16px;
    transition: 0.2s ease-in-out; }
    .snackbar-button .snackbar-button-content:hover {
      background-color: rgba(255, 255, 255, 0.08); }

.snackbar-button-text {
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700; }

.snackbar-success {
  background-color: #469c53; }

.snackbar-error {
  background-color: #f34747; }

.snackbar-info {
  background-color: #2973c0; }
  .snackbar-info .snackbar-button {
    background-color: #f3f4f6; }
  .snackbar-info .snackbar-button-text {
    color: #3e3d42; }
