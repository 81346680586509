/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.no-news-available-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  height: 252px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 350;
  text-align: center; }
  .no-news-available-container .file-icon {
    width: 90px;
    height: 90px; }
    .no-news-available-container .file-icon > * {
      width: 100%;
      height: 100%; }
