/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.desktop-headline-news {
  position: relative; }
  .desktop-headline-news .swiper-button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    background-color: #ffffff;
    box-shadow: -4px 4px 16px -4px rgba(58, 9, 122, 0.16);
    cursor: pointer; }
    .desktop-headline-news .swiper-button .swiper-button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #3e3d42;
      transition: 0.2s ease-in-out; }
      .desktop-headline-news .swiper-button .swiper-button-content:hover {
        background-color: rgba(0, 0, 0, 0.08); }
  .desktop-headline-news .next-swiper-button {
    top: calc(50% - 20px);
    right: -20px; }
  .desktop-headline-news .previous-swiper-button {
    top: calc(50% - 20px);
    left: -20px; }
  .desktop-headline-news .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: #c2c3c5;
    opacity: 1; }
  .desktop-headline-news .swiper-pagination-bullet-active {
    border: 4px solid #3e3d42;
    background-color: #fdea4f; }
