.available-custom-tool {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  overflow: auto;
  height: 375px; }

.add-app-loader {
  width: 10%;
  position: absolute;
  top: 45%;
  left: 45%; }

.loading-available-custom-tool {
  opacity: 0.2; }
