/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.themes-displayer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px; }

.themes-displayer-theme {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fdea4f;
  font-weight: 350;
  font-size: 10px;
  line-height: 12px;
  color: #3e3d42;
  text-transform: uppercase; }
