/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.publication-scope-selector-modal-button-container {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3e3d42;
  font-size: 14px;
  line-height: 20px;
  font-weight: 350;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 14px;
  cursor: pointer;
  transition: 0.2s ease-in-out; }
  .publication-scope-selector-modal-button-container:hover {
    background-color: rgba(0, 0, 0, 0.08); }
  .publication-scope-selector-modal-button-container .selector-icon {
    font-size: 20px; }
  .publication-scope-selector-modal-button-container .details-block {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px; }
