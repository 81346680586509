@import 'scss/base/utils';

@include max-width(m) {
  .news-details-themes-and-date {
    gap: 8px;
    align-items: flex-start;
    flex-direction: column;
  }
  .news-details-stats {
    display: flex;
    align-items: flex-end;
  }
}
