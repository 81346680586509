@charset "UTF-8";
/* ========

   Mixins

 ======== */
/**

Screen Size Media Query Mixins

**/
/**
* Like max width, but offsets by a pixel to avoid a min-width and max-width from matching at the same time
*/
/*

Screen resolution mixin

*/
/**
Text selection mixin
*/
/*
Form placeholder mixin
*/
/*
Keyframe animation cross-browser (autoprefixer doesn't handle this well)
*/
/*

Aspect ratio mixin. can accept width and height or just the ratio

Relies on a floated element's padding

found at http://codepen.io/jakob-e/pen/LEdWNB?editors=110 linked to from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

*/
/*

Sprite mixin

*/
/* ========

   Functions

 ======== */
/* ========

   Helper classes

 ======== */
br.max-width-xxs,
br.min-width-xxs,
br.below-width-xxs {
  display: none; }

@media screen and (max-width: 320px) {
  br.max-width-xxs {
    display: block; } }

@media screen and (min-width: 320px) {
  br.min-width-xxs {
    display: block; } }

@media screen and (max-width: 319px) {
  br.below-xxs {
    display: block; } }

.show-min-width-xxs,
.show-max-width-xxs,
.show-below-xxs {
  display: none; }

@media screen and (max-width: 320px) {
  .show-max-width-xxs {
    display: inherit; } }

@media screen and (min-width: 320px) {
  .show-min-width-xxs {
    display: inherit; } }

@media screen and (max-width: 319px) {
  .show-below-xxs {
    display: inherit; } }

br.max-width-xs,
br.min-width-xs,
br.below-width-xs {
  display: none; }

@media screen and (max-width: 550px) {
  br.max-width-xs {
    display: block; } }

@media screen and (min-width: 550px) {
  br.min-width-xs {
    display: block; } }

@media screen and (max-width: 549px) {
  br.below-xs {
    display: block; } }

.show-min-width-xs,
.show-max-width-xs,
.show-below-xs {
  display: none; }

@media screen and (max-width: 550px) {
  .show-max-width-xs {
    display: inherit; } }

@media screen and (min-width: 550px) {
  .show-min-width-xs {
    display: inherit; } }

@media screen and (max-width: 549px) {
  .show-below-xs {
    display: inherit; } }

br.max-width-s,
br.min-width-s,
br.below-width-s {
  display: none; }

@media screen and (max-width: 750px) {
  br.max-width-s {
    display: block; } }

@media screen and (min-width: 750px) {
  br.min-width-s {
    display: block; } }

@media screen and (max-width: 749px) {
  br.below-s {
    display: block; } }

.show-min-width-s,
.show-max-width-s,
.show-below-s {
  display: none; }

@media screen and (max-width: 750px) {
  .show-max-width-s {
    display: inherit; } }

@media screen and (min-width: 750px) {
  .show-min-width-s {
    display: inherit; } }

@media screen and (max-width: 749px) {
  .show-below-s {
    display: inherit; } }

br.max-width-m,
br.min-width-m,
br.below-width-m {
  display: none; }

@media screen and (max-width: 980px) {
  br.max-width-m {
    display: block; } }

@media screen and (min-width: 980px) {
  br.min-width-m {
    display: block; } }

@media screen and (max-width: 979px) {
  br.below-m {
    display: block; } }

.show-min-width-m,
.show-max-width-m,
.show-below-m {
  display: none; }

@media screen and (max-width: 980px) {
  .show-max-width-m {
    display: inherit; } }

@media screen and (min-width: 980px) {
  .show-min-width-m {
    display: inherit; } }

@media screen and (max-width: 979px) {
  .show-below-m {
    display: inherit; } }

br.max-width-l,
br.min-width-l,
br.below-width-l {
  display: none; }

@media screen and (max-width: 1200px) {
  br.max-width-l {
    display: block; } }

@media screen and (min-width: 1200px) {
  br.min-width-l {
    display: block; } }

@media screen and (max-width: 1199px) {
  br.below-l {
    display: block; } }

.show-min-width-l,
.show-max-width-l,
.show-below-l {
  display: none; }

@media screen and (max-width: 1200px) {
  .show-max-width-l {
    display: inherit; } }

@media screen and (min-width: 1200px) {
  .show-min-width-l {
    display: inherit; } }

@media screen and (max-width: 1199px) {
  .show-below-l {
    display: inherit; } }

br.max-width-xl,
br.min-width-xl,
br.below-width-xl {
  display: none; }

@media screen and (max-width: 1400px) {
  br.max-width-xl {
    display: block; } }

@media screen and (min-width: 1400px) {
  br.min-width-xl {
    display: block; } }

@media screen and (max-width: 1399px) {
  br.below-xl {
    display: block; } }

.show-min-width-xl,
.show-max-width-xl,
.show-below-xl {
  display: none; }

@media screen and (max-width: 1400px) {
  .show-max-width-xl {
    display: inherit; } }

@media screen and (min-width: 1400px) {
  .show-min-width-xl {
    display: inherit; } }

@media screen and (max-width: 1399px) {
  .show-below-xl {
    display: inherit; } }

br.max-width-xxl,
br.min-width-xxl,
br.below-width-xxl {
  display: none; }

@media screen and (max-width: 1600px) {
  br.max-width-xxl {
    display: block; } }

@media screen and (min-width: 1600px) {
  br.min-width-xxl {
    display: block; } }

@media screen and (max-width: 1599px) {
  br.below-xxl {
    display: block; } }

.show-min-width-xxl,
.show-max-width-xxl,
.show-below-xxl {
  display: none; }

@media screen and (max-width: 1600px) {
  .show-max-width-xxl {
    display: inherit; } }

@media screen and (min-width: 1600px) {
  .show-min-width-xxl {
    display: inherit; } }

@media screen and (max-width: 1599px) {
  .show-below-xxl {
    display: inherit; } }

br.max-width-tablet-landscape-max-width,
br.min-width-tablet-landscape-max-width,
br.below-width-tablet-landscape-max-width {
  display: none; }

@media screen and (max-width: 1439px) {
  br.max-width-tablet-landscape-max-width {
    display: block; } }

@media screen and (min-width: 1439px) {
  br.min-width-tablet-landscape-max-width {
    display: block; } }

@media screen and (max-width: 1438px) {
  br.below-tablet-landscape-max-width {
    display: block; } }

.show-min-width-tablet-landscape-max-width,
.show-max-width-tablet-landscape-max-width,
.show-below-tablet-landscape-max-width {
  display: none; }

@media screen and (max-width: 1439px) {
  .show-max-width-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (min-width: 1439px) {
  .show-min-width-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (max-width: 1438px) {
  .show-below-tablet-landscape-max-width {
    display: inherit; } }

/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.link {
  background-color: #cccccc; }

.shaded-tool {
  opacity: 0.5;
  pointer-events: none; }

.tool-container {
  position: relative; }

.disabled-tool {
  pointer-events: none; }

.tool {
  position: relative;
  display: block;
  text-align: center;
  font-size: 13px;
  color: black;
  text-decoration: none;
  cursor: pointer; }
  .tool .tool-tile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18%;
    pointer-events: none; }
    .tool .tool-tile:before {
      content: '';
      display: block;
      float: left;
      padding-bottom: 100%; }
    .tool .tool-tile:after {
      content: '';
      display: table;
      clear: both; }
    .tool .tool-tile .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white; }
    .tool .tool-tile .initial-icon {
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
      color: #222222; }
  .tool .tool-delete {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    padding: 5%;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #ccd0d6;
    color: white;
    z-index: 1; }
    .tool .tool-delete .icon {
      width: 75%;
      height: 75%; }
  .tool .tool-details {
    margin-top: 1em;
    pointer-events: none; }
  .tool .tool-title {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2px;
    pointer-events: none; }
  .tool .tool-subtitle {
    color: rgba(0, 0, 0, 0.3);
    margin: auto; }
  .tool.tool-add {
    display: none; }
  .tool.placeholder .tool-tile {
    background-color: #e9ebee; }
  .tool.placeholder .tool-title {
    background-color: #f2f3f5;
    pointer-events: none;
    border-radius: 3px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1;
    margin-bottom: 0.6em; }
  .tool.placeholder .tool-subtitle {
    background-color: #f2f3f5;
    border-radius: 3px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1; }

.loading-tool {
  opacity: 0.2; }

.tool-loader {
  width: 50px;
  top: 22px;
  left: 22px;
  position: absolute; }

.tools-delete-button {
  position: absolute;
  z-index: 1;
  top: -12px;
  right: -12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f34747;
  overflow: hidden; }
  .tools-delete-button .tools-delete-button-content {
    width: 100%;
    height: 100%;
    padding: 10px;
    color: white;
    transition: 0.2s ease-in-out; }
    .tools-delete-button .tools-delete-button-content > * {
      width: 100%;
      height: 100%; }
    .tools-delete-button .tools-delete-button-content:hover {
      background-color: rgba(0, 0, 0, 0.08); }

/*
  ╔════╤══════════════════════════╤═══╗
  ║••• │                          │ ? ║
  ║────┴──────────────────────────┴───║
  ║                                   ║
  ║                                   ║
  ║       Screen Size : m             ║
  ║                                   ║
  ║                                   ║
  ║                                   ║
  ╚═══════════════════════════════════╝
*/
@media screen and (min-width: 980px) {
  .tool.tool-add {
    display: block; }
    .tool.tool-add:hover .tool-tile .icon {
      transform: translate(-50%, -50%) scale(1.2); }
    .tool.tool-add .icon {
      transition: transform 150ms ease-in-out; }
    .tool.tool-add .tool-tile {
      pointer-events: none;
      border-radius: 50%;
      background-color: #3d3d42;
      margin: 15%;
      color: white; } }
