/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.error-block-with-reload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px; }
  .error-block-with-reload-container .error-block-message {
    margin-top: 10px;
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    color: #3e3d42;
    text-align: center; }
