.container {
  background-image: url("images/world-map.svg");
  background-size: cover;
  background-position: center;
  height: 272px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px; }
