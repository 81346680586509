.global-loader {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 30px; }
  .global-loader .global-loader-logo {
    width: 90%;
    max-width: 230px; }
  .global-loader .loader {
    max-width: 50px;
    height: 50px; }
