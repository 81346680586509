// Brand Colors

$colas-brand: #ffec00;
$colas-highlight: #00c0e7;

$component-border: #e9ebee;

// Texts Color
$text-color-main: #3d3d42;
$text-color-light: #ccd0d6;
$title-color: #2c4965;

//Border color

$border-color: #e5e5e5;
// Menu Colors

$menu-item-color: #e9ebee;
$menu-item-color-hover: #3d3d42;
$menu-item-background-color-hover: #f2f4f6;
$menu-avatar-empty-color: #6d2ebf;

// Forms Colors

$input-label-color: #4e4e52;

$input-checkbox-border: #979797;

$form-error: #e74125;

$button-color: #3d3d42;
$primary-button-background-color: #53c0df;
$secondary-button-background-color: #f3f4f6;

$selected-pagination-button-background: #53c0df;

$placeholder-color: #929292;
$secondary-info-color: #929292;

$label-color: #3e3d42;

// Modal Colors
$modal-header-background-color: $menu-item-background-color-hover;

$light-grey: #f3f4f6;
$info-color: #2973c0;
$error-color: #f34747;
$success-color: #469c53;
$color-border-select-language: #e5e5e5;
$go-back-link-color: #53c0df;
$snackbar-button-background: rgba(255, 255, 255, 0.32);
$snackbar-border-shadow: rgba(58, 9, 122, 0.16);

$modal-border-shadow: rgba(58, 9, 122, 0.12);

$table-border-shadow: rgba(58, 9, 122, 0.05);

$scrollbar-background-color: rgba(255, 255, 255, 0);

$translation-modal-background: rgba(0, 0, 0, 0.5);

$scrollbar-color: #e5e5e5;

$background-color: #ffffff;
$input-background-color: #f5f6f7;
$search-results-list-shadow: rgba(58, 9, 122, 0.12);

$preview-overlay: rgba(0, 0, 0, 0.5);

$theme-background-color: #fdea4f;
$colas-yellow: #fdea4f;

$link-tool-color: #cccccc;

$grey-bouygues-link-background-color: #595959;

$blue-bouygues-link-background-color: #0679cc;

$custom-tools-background-color: #009e60;

$not-published-status: #ef7532;
$publishable-status: #2973c0;
$scheduled-status: #2973c0;
$published-status: #469c53;

$unselected-clickable: #c2c3c5;

$scope-type-darkest-blue: #102e4d;
$scope-type-dark-blue: #1d5186;
$scope-type-blue: #2973c0;
$scope-type-light-blue: #699dd3;

$global-tool-link-box-color: #53c0df;
$local-tool-link-box-color: #fdea4f;

$hover-opacity: rgba(0, 0, 0, 0.08);
$transition-default-behaviour: 0.2s ease-in-out;
