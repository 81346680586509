@import 'scss/vars/colors';
@import 'scss/base/fonts';
@import 'scss/base/utils';

.contact-directory-container {
  height: 48px;
  width: 100%;
  max-width: 100%;
}

@include min-width(m) {
  .contact-directory-container {
    width: 320px;
    max-width: 320px;
  }
}
