.toolkit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px; }

.tool-links-container {
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%; }

.toolkit-loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  .toolkit-loader-block .loader {
    width: 50px;
    height: 50px; }
