/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.go-back-container {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #53c0df;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  transition: 0.2s ease-in-out; }
  .go-back-container .go-back-icon {
    display: flex;
    align-items: center; }
  .go-back-container:hover {
    background-color: transparent;
    filter: brightness(0.92); }
