/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.search-container {
  display: flex;
  align-items: center;
  height: 46px;
  width: 100%;
  padding: 12px 8px;
  gap: 8px; }
  .search-container .search-icon {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 12px; }
    .search-container .search-icon > * {
      color: #3e3d42;
      width: 100%;
      height: 100%; }
    .search-container .search-icon.clickable {
      cursor: pointer;
      transition: 0.2s ease-in-out; }
      .search-container .search-icon.clickable:hover {
        background-color: rgba(0, 0, 0, 0.08); }
  .search-container .clear-search-icon {
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.2s ease-in-out; }
    .search-container .clear-search-icon > * {
      color: #3e3d42;
      width: 100%;
      height: 100%; }
    .search-container .clear-search-icon:hover {
      background-color: rgba(0, 0, 0, 0.08); }
  .search-container .search-input {
    flex-grow: 1; }
    .search-container .search-input > input {
      width: 100%;
      min-width: unset;
      background-color: unset;
      padding: unset;
      border-radius: unset;
      font-size: 14px;
      line-height: 20px; }
    .search-container .search-input ::placeholder {
      color: #929292; }
