$default-font: 'Gotham';

/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/

$font-weight-thin       : 100;
$font-weight-extralight : 200;
$font-weight-light      : 300;
$font-weight-book       : 350;
$font-weight-regular    : 400;
$font-weight-medium     : 500;
$font-weight-semibold   : 600;
$font-weight-bold       : 700;
$font-weight-black      : 800;