/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.add-url-form-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px; }
  .add-url-form-container input {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px; }
  .add-url-form-container label {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #3e3d42;
    padding-bottom: 8px; }
  .add-url-form-container .custom-button {
    align-self: center; }
