@import 'scss/vars/_colors';
@import 'scss/base/utils';

.thumbnail-aspect-ratio-container {
  width: 100%;
  // Restrain width to max thumbnail's width
  max-width: 1200px;

  .thumbnail-aspect-ratio {
    position: relative;
    // To force aspect ratio for pictures of 1200*675 px: 675/1200 * 100 = 56.25%
    padding-top: 56.25%;

    .thumbnail-content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      overflow: hidden;
      background-color: $light_grey;

      &.expandable {
        cursor: pointer;
      }

      .thumbnail {
        max-height: 100%;
        max-width: 100%;
      }
      .thumbnail-shadow {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 76.97%, rgba(0, 0, 0, 0.56) 100%);
      }

      .thumbnail-expand {
        position: absolute;
        bottom: 24px;
        right: 24px;
        height: 40px;
        width: 40px;
        border-radius: 8px;
        background-color: white;
        overflow: hidden;

        .thumbnail-expand-content {
          padding: 11px;
          color: $label_color;

          > * {
            width: 100%;
            height: 100%;
          }

          transition: $transition-default-behaviour;

          &:hover {
            background-color: $hover-opacity;
          }
        }
      }
    }
  }
}
