@import 'scss/base/utils';
@import 'scss/vars/_colors';
@import 'scss/base/fonts';

@import './SearchNewsModalMobile';
@import './SearchNewsModalDesktop';

.search-news-modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-bold;
    color: $label-color;
    text-transform: uppercase;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .search-block {
    display: flex;
    gap: 16px;
  }

  .search-input-container {
    flex-grow: 1;
    border-bottom: 1px solid $colas-yellow;

    input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .search-button {
    .custom-button-content {
      padding: 12px 24px;
    }
  }

  .results-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .results-total {
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight-book;
    color: $secondary-info-color;
    display: flex;
    justify-content: space-between;
  }

  .results {
    flex-grow: 1;
    height: 1px; // height is needed to make flex-grow work
    overflow-y: auto;

    a {
      text-decoration: none;
    }
  }

  .result {
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .explanations-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 34px;

    .explanations {
      max-width: 400px;
      font-size: 16px;
      line-height: 24px;
      font-weight: $font-weight-regular;
      color: $placeholder-color;
      text-align: center;
    }
  }

  .search-loader-block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .warning-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 34px;

    .warning-message {
      max-width: 400px;
      font-size: 16px;
      line-height: 24px;
      font-weight: $font-weight-regular;
      color: $label-color;
      text-align: center;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
  }
}
