/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.news-details-container {
  display: flex;
  flex-direction: column;
  gap: 32px; }
  .news-details-container .news-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .news-details-container .news-details-header-buttons {
    display: flex;
    flex-direction: row;
    gap: 16px; }
  .news-details-container .news-details-full-height-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 160px); }
  .news-details-container .news-details-loader {
    width: 100px;
    height: 100px; }
