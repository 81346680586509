/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.modal-content[data-name='add-tool'] {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 630px;
  height: 490px;
  top: calc(50% - 490px/2);
  left: calc(50% - 630px/2);
  background-color: white; }
  .modal-content[data-name='add-tool'] .modal-loader {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.6; }
    .modal-content[data-name='add-tool'] .modal-loader .loader {
      width: 30px;
      height: 30px;
      margin-bottom: 20px; }
      .modal-content[data-name='add-tool'] .modal-loader .loader circle {
        stroke: white; }
    .modal-content[data-name='add-tool'] .modal-loader p {
      max-width: 160px;
      text-align: center;
      color: white; }
  .modal-content[data-name='add-tool'] .modal-header {
    position: relative;
    height: 92px;
    line-height: 92px; }
  .modal-content[data-name='add-tool'] .modal-header-button-container {
    height: 100%;
    padding: 0 14px; }
  .modal-content[data-name='add-tool'] .modal-header-button {
    color: #00c0e7;
    font-weight: 600;
    font-size: 20px;
    margin: auto 14px;
    height: calc(100% - 4px);
    border-bottom: 4px solid transparent;
    outline: none;
    cursor: pointer;
    transition: border-color 150ms ease; }
    .modal-content[data-name='add-tool'] .modal-header-button.active, .modal-content[data-name='add-tool'] .modal-header-button:hover {
      border-bottom-color: #00c0e7; }
  .modal-content[data-name='add-tool'] .modal-header-close {
    position: absolute;
    top: 34px;
    right: 40px;
    width: 20px;
    height: 20px;
    cursor: pointer; }
    .modal-content[data-name='add-tool'] .modal-header-close:hover > .icon {
      transform: scale(0.8); }
    .modal-content[data-name='add-tool'] .modal-header-close > .icon {
      display: block;
      color: #3d3d42;
      transition: transform 250ms ease; }
  .modal-content[data-name='add-tool'] .modal-body {
    flex-grow: 1;
    position: relative; }
  .modal-content[data-name='add-tool'] .modal-body-pane {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto; }
    .modal-content[data-name='add-tool'] .modal-body-pane.add-link {
      background-color: #f3f4f6; }
      .modal-content[data-name='add-tool'] .modal-body-pane.add-link input {
        background-color: white; }
  .modal-content[data-name='add-tool'] .frontoffice-tool-list {
    min-height: 100%;
    text-align: center; }
    .modal-content[data-name='add-tool'] .frontoffice-tool-list.empty {
      display: flex;
      justify-content: center;
      align-items: center; }
      .modal-content[data-name='add-tool'] .frontoffice-tool-list.empty .message {
        color: #949499;
        font-size: 1.5em; }
  .modal-content[data-name='add-tool'] .link-form {
    padding: 30px; }
    .modal-content[data-name='add-tool'] .link-form.state-submitting .loader {
      display: inline-block; }
    .modal-content[data-name='add-tool'] .link-form .form-control {
      margin-bottom: 15px; }
    .modal-content[data-name='add-tool'] .link-form input[type='text'] {
      width: 440px;
      height: 40px; }
    .modal-content[data-name='add-tool'] .link-form .label-value {
      display: inline-block;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      width: 110px; }
    .modal-content[data-name='add-tool'] .link-form .link-form-end {
      padding-left: 110px; }
    .modal-content[data-name='add-tool'] .link-form .link-form-hint {
      margin: 0 auto 25px auto;
      color: #ccd0d6;
      font-size: 14px; }
