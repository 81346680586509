/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.apps-modal-container {
  position: absolute;
  width: 53%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #ffffff;
  outline: 0;
  padding: 40px;
  gap: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column; }

.apps-modal-header {
  display: flex;
  justify-content: space-between; }

.apps-modal-buttons {
  display: flex;
  gap: 16px; }
