/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.publication-scope-selector-modal-by-activity-content-container {
  width: 100%;
  height: 100%; }
  .publication-scope-selector-modal-by-activity-content-container .publication-scope-selector-modal-loader-block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .publication-scope-selector-modal-by-activity-content-container .publication-scope-selector-modal-loader-block .publication-scope-selector-modal-loader-container {
      width: 15%;
      height: 15%; }
  .publication-scope-selector-modal-by-activity-content-container .publication-scope-selector-modal-error-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; }
  .publication-scope-selector-modal-by-activity-content-container .publication-scope-selector-modal-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; }
    .publication-scope-selector-modal-by-activity-content-container .publication-scope-selector-modal-content .publication-scope-selector-modal-scope-tabs {
      display: flex; }
    .publication-scope-selector-modal-by-activity-content-container .publication-scope-selector-modal-content .publication-scope-selector-modal-scopes-list {
      flex-grow: 1;
      overflow: hidden; }
