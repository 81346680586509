@charset "UTF-8";
/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
/* ========

   Mixins

 ======== */
/**

Screen Size Media Query Mixins

**/
/**
* Like max width, but offsets by a pixel to avoid a min-width and max-width from matching at the same time
*/
/*

Screen resolution mixin

*/
/**
Text selection mixin
*/
/*
Form placeholder mixin
*/
/*
Keyframe animation cross-browser (autoprefixer doesn't handle this well)
*/
/*

Aspect ratio mixin. can accept width and height or just the ratio

Relies on a floated element's padding

found at http://codepen.io/jakob-e/pen/LEdWNB?editors=110 linked to from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

*/
/*

Sprite mixin

*/
/* ========

   Functions

 ======== */
/* ========

   Helper classes

 ======== */
br.max-width-xxs,
br.min-width-xxs,
br.below-width-xxs {
  display: none; }

@media screen and (max-width: 320px) {
  br.max-width-xxs {
    display: block; } }

@media screen and (min-width: 320px) {
  br.min-width-xxs {
    display: block; } }

@media screen and (max-width: 319px) {
  br.below-xxs {
    display: block; } }

.show-min-width-xxs,
.show-max-width-xxs,
.show-below-xxs {
  display: none; }

@media screen and (max-width: 320px) {
  .show-max-width-xxs {
    display: inherit; } }

@media screen and (min-width: 320px) {
  .show-min-width-xxs {
    display: inherit; } }

@media screen and (max-width: 319px) {
  .show-below-xxs {
    display: inherit; } }

br.max-width-xs,
br.min-width-xs,
br.below-width-xs {
  display: none; }

@media screen and (max-width: 550px) {
  br.max-width-xs {
    display: block; } }

@media screen and (min-width: 550px) {
  br.min-width-xs {
    display: block; } }

@media screen and (max-width: 549px) {
  br.below-xs {
    display: block; } }

.show-min-width-xs,
.show-max-width-xs,
.show-below-xs {
  display: none; }

@media screen and (max-width: 550px) {
  .show-max-width-xs {
    display: inherit; } }

@media screen and (min-width: 550px) {
  .show-min-width-xs {
    display: inherit; } }

@media screen and (max-width: 549px) {
  .show-below-xs {
    display: inherit; } }

br.max-width-s,
br.min-width-s,
br.below-width-s {
  display: none; }

@media screen and (max-width: 750px) {
  br.max-width-s {
    display: block; } }

@media screen and (min-width: 750px) {
  br.min-width-s {
    display: block; } }

@media screen and (max-width: 749px) {
  br.below-s {
    display: block; } }

.show-min-width-s,
.show-max-width-s,
.show-below-s {
  display: none; }

@media screen and (max-width: 750px) {
  .show-max-width-s {
    display: inherit; } }

@media screen and (min-width: 750px) {
  .show-min-width-s {
    display: inherit; } }

@media screen and (max-width: 749px) {
  .show-below-s {
    display: inherit; } }

br.max-width-m,
br.min-width-m,
br.below-width-m {
  display: none; }

@media screen and (max-width: 980px) {
  br.max-width-m {
    display: block; } }

@media screen and (min-width: 980px) {
  br.min-width-m {
    display: block; } }

@media screen and (max-width: 979px) {
  br.below-m {
    display: block; } }

.show-min-width-m,
.show-max-width-m,
.show-below-m {
  display: none; }

@media screen and (max-width: 980px) {
  .show-max-width-m {
    display: inherit; } }

@media screen and (min-width: 980px) {
  .show-min-width-m {
    display: inherit; } }

@media screen and (max-width: 979px) {
  .show-below-m {
    display: inherit; } }

br.max-width-l,
br.min-width-l,
br.below-width-l {
  display: none; }

@media screen and (max-width: 1200px) {
  br.max-width-l {
    display: block; } }

@media screen and (min-width: 1200px) {
  br.min-width-l {
    display: block; } }

@media screen and (max-width: 1199px) {
  br.below-l {
    display: block; } }

.show-min-width-l,
.show-max-width-l,
.show-below-l {
  display: none; }

@media screen and (max-width: 1200px) {
  .show-max-width-l {
    display: inherit; } }

@media screen and (min-width: 1200px) {
  .show-min-width-l {
    display: inherit; } }

@media screen and (max-width: 1199px) {
  .show-below-l {
    display: inherit; } }

br.max-width-xl,
br.min-width-xl,
br.below-width-xl {
  display: none; }

@media screen and (max-width: 1400px) {
  br.max-width-xl {
    display: block; } }

@media screen and (min-width: 1400px) {
  br.min-width-xl {
    display: block; } }

@media screen and (max-width: 1399px) {
  br.below-xl {
    display: block; } }

.show-min-width-xl,
.show-max-width-xl,
.show-below-xl {
  display: none; }

@media screen and (max-width: 1400px) {
  .show-max-width-xl {
    display: inherit; } }

@media screen and (min-width: 1400px) {
  .show-min-width-xl {
    display: inherit; } }

@media screen and (max-width: 1399px) {
  .show-below-xl {
    display: inherit; } }

br.max-width-xxl,
br.min-width-xxl,
br.below-width-xxl {
  display: none; }

@media screen and (max-width: 1600px) {
  br.max-width-xxl {
    display: block; } }

@media screen and (min-width: 1600px) {
  br.min-width-xxl {
    display: block; } }

@media screen and (max-width: 1599px) {
  br.below-xxl {
    display: block; } }

.show-min-width-xxl,
.show-max-width-xxl,
.show-below-xxl {
  display: none; }

@media screen and (max-width: 1600px) {
  .show-max-width-xxl {
    display: inherit; } }

@media screen and (min-width: 1600px) {
  .show-min-width-xxl {
    display: inherit; } }

@media screen and (max-width: 1599px) {
  .show-below-xxl {
    display: inherit; } }

br.max-width-tablet-landscape-max-width,
br.min-width-tablet-landscape-max-width,
br.below-width-tablet-landscape-max-width {
  display: none; }

@media screen and (max-width: 1439px) {
  br.max-width-tablet-landscape-max-width {
    display: block; } }

@media screen and (min-width: 1439px) {
  br.min-width-tablet-landscape-max-width {
    display: block; } }

@media screen and (max-width: 1438px) {
  br.below-tablet-landscape-max-width {
    display: block; } }

.show-min-width-tablet-landscape-max-width,
.show-max-width-tablet-landscape-max-width,
.show-below-tablet-landscape-max-width {
  display: none; }

@media screen and (max-width: 1439px) {
  .show-max-width-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (min-width: 1439px) {
  .show-min-width-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (max-width: 1438px) {
  .show-below-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (max-width: 980px) {
  #select-language {
    width: 80px;
    height: 40px;
    gap: 16px; }
  .country-name {
    display: none; } }

@media screen and (min-width: 980px) {
  #select-language {
    gap: 15px; }
  .country-name {
    display: block; } }

/* ========

   Mixins

 ======== */
/**

Screen Size Media Query Mixins

**/
/**
* Like max width, but offsets by a pixel to avoid a min-width and max-width from matching at the same time
*/
/*

Screen resolution mixin

*/
/**
Text selection mixin
*/
/*
Form placeholder mixin
*/
/*
Keyframe animation cross-browser (autoprefixer doesn't handle this well)
*/
/*

Aspect ratio mixin. can accept width and height or just the ratio

Relies on a floated element's padding

found at http://codepen.io/jakob-e/pen/LEdWNB?editors=110 linked to from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/

*/
/*

Sprite mixin

*/
/* ========

   Functions

 ======== */
/* ========

   Helper classes

 ======== */
br.max-width-xxs,
br.min-width-xxs,
br.below-width-xxs {
  display: none; }

@media screen and (max-width: 320px) {
  br.max-width-xxs {
    display: block; } }

@media screen and (min-width: 320px) {
  br.min-width-xxs {
    display: block; } }

@media screen and (max-width: 319px) {
  br.below-xxs {
    display: block; } }

.show-min-width-xxs,
.show-max-width-xxs,
.show-below-xxs {
  display: none; }

@media screen and (max-width: 320px) {
  .show-max-width-xxs {
    display: inherit; } }

@media screen and (min-width: 320px) {
  .show-min-width-xxs {
    display: inherit; } }

@media screen and (max-width: 319px) {
  .show-below-xxs {
    display: inherit; } }

br.max-width-xs,
br.min-width-xs,
br.below-width-xs {
  display: none; }

@media screen and (max-width: 550px) {
  br.max-width-xs {
    display: block; } }

@media screen and (min-width: 550px) {
  br.min-width-xs {
    display: block; } }

@media screen and (max-width: 549px) {
  br.below-xs {
    display: block; } }

.show-min-width-xs,
.show-max-width-xs,
.show-below-xs {
  display: none; }

@media screen and (max-width: 550px) {
  .show-max-width-xs {
    display: inherit; } }

@media screen and (min-width: 550px) {
  .show-min-width-xs {
    display: inherit; } }

@media screen and (max-width: 549px) {
  .show-below-xs {
    display: inherit; } }

br.max-width-s,
br.min-width-s,
br.below-width-s {
  display: none; }

@media screen and (max-width: 750px) {
  br.max-width-s {
    display: block; } }

@media screen and (min-width: 750px) {
  br.min-width-s {
    display: block; } }

@media screen and (max-width: 749px) {
  br.below-s {
    display: block; } }

.show-min-width-s,
.show-max-width-s,
.show-below-s {
  display: none; }

@media screen and (max-width: 750px) {
  .show-max-width-s {
    display: inherit; } }

@media screen and (min-width: 750px) {
  .show-min-width-s {
    display: inherit; } }

@media screen and (max-width: 749px) {
  .show-below-s {
    display: inherit; } }

br.max-width-m,
br.min-width-m,
br.below-width-m {
  display: none; }

@media screen and (max-width: 980px) {
  br.max-width-m {
    display: block; } }

@media screen and (min-width: 980px) {
  br.min-width-m {
    display: block; } }

@media screen and (max-width: 979px) {
  br.below-m {
    display: block; } }

.show-min-width-m,
.show-max-width-m,
.show-below-m {
  display: none; }

@media screen and (max-width: 980px) {
  .show-max-width-m {
    display: inherit; } }

@media screen and (min-width: 980px) {
  .show-min-width-m {
    display: inherit; } }

@media screen and (max-width: 979px) {
  .show-below-m {
    display: inherit; } }

br.max-width-l,
br.min-width-l,
br.below-width-l {
  display: none; }

@media screen and (max-width: 1200px) {
  br.max-width-l {
    display: block; } }

@media screen and (min-width: 1200px) {
  br.min-width-l {
    display: block; } }

@media screen and (max-width: 1199px) {
  br.below-l {
    display: block; } }

.show-min-width-l,
.show-max-width-l,
.show-below-l {
  display: none; }

@media screen and (max-width: 1200px) {
  .show-max-width-l {
    display: inherit; } }

@media screen and (min-width: 1200px) {
  .show-min-width-l {
    display: inherit; } }

@media screen and (max-width: 1199px) {
  .show-below-l {
    display: inherit; } }

br.max-width-xl,
br.min-width-xl,
br.below-width-xl {
  display: none; }

@media screen and (max-width: 1400px) {
  br.max-width-xl {
    display: block; } }

@media screen and (min-width: 1400px) {
  br.min-width-xl {
    display: block; } }

@media screen and (max-width: 1399px) {
  br.below-xl {
    display: block; } }

.show-min-width-xl,
.show-max-width-xl,
.show-below-xl {
  display: none; }

@media screen and (max-width: 1400px) {
  .show-max-width-xl {
    display: inherit; } }

@media screen and (min-width: 1400px) {
  .show-min-width-xl {
    display: inherit; } }

@media screen and (max-width: 1399px) {
  .show-below-xl {
    display: inherit; } }

br.max-width-xxl,
br.min-width-xxl,
br.below-width-xxl {
  display: none; }

@media screen and (max-width: 1600px) {
  br.max-width-xxl {
    display: block; } }

@media screen and (min-width: 1600px) {
  br.min-width-xxl {
    display: block; } }

@media screen and (max-width: 1599px) {
  br.below-xxl {
    display: block; } }

.show-min-width-xxl,
.show-max-width-xxl,
.show-below-xxl {
  display: none; }

@media screen and (max-width: 1600px) {
  .show-max-width-xxl {
    display: inherit; } }

@media screen and (min-width: 1600px) {
  .show-min-width-xxl {
    display: inherit; } }

@media screen and (max-width: 1599px) {
  .show-below-xxl {
    display: inherit; } }

br.max-width-tablet-landscape-max-width,
br.min-width-tablet-landscape-max-width,
br.below-width-tablet-landscape-max-width {
  display: none; }

@media screen and (max-width: 1439px) {
  br.max-width-tablet-landscape-max-width {
    display: block; } }

@media screen and (min-width: 1439px) {
  br.min-width-tablet-landscape-max-width {
    display: block; } }

@media screen and (max-width: 1438px) {
  br.below-tablet-landscape-max-width {
    display: block; } }

.show-min-width-tablet-landscape-max-width,
.show-max-width-tablet-landscape-max-width,
.show-below-tablet-landscape-max-width {
  display: none; }

@media screen and (max-width: 1439px) {
  .show-max-width-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (min-width: 1439px) {
  .show-min-width-tablet-landscape-max-width {
    display: inherit; } }

@media screen and (max-width: 1438px) {
  .show-below-tablet-landscape-max-width {
    display: inherit; } }

p {
  margin: 0; }

button {
  outline: none; }

div {
  word-wrap: break-word; }

.portal-section {
  margin: 50px auto;
  width: 88%; }
  .portal-section .portal-section-title {
    color: #3d3d42;
    font-size: 30px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .portal-section .portal-section-title .extra-items {
      display: none; }
    .portal-section .portal-section-title .loading-indicator {
      display: flex;
      align-content: center;
      font-size: 16px;
      line-height: 1;
      margin-top: 10px; }
  .portal-section.full-width {
    width: 100%; }
    .portal-section.full-width .portal-section-title {
      width: 88%;
      margin: 1em auto; }

.flag {
  border-radius: 10%;
  overflow: hidden; }

/*
  ╔════╤══════════════════════════╤═══╗
  ║••• │                          │ ? ║
  ║────┴──────────────────────────┴───║
  ║                                   ║
  ║                                   ║
  ║       Screen Size : xs            ║
  ║                                   ║
  ║                                   ║
  ║                                   ║
  ╚═══════════════════════════════════╝
*/
/*
  ╔════╤══════════════════════════╤═══╗
  ║••• │                          │ ? ║
  ║────┴──────────────────────────┴───║
  ║                                   ║
  ║                                   ║
  ║       Screen Size : m             ║
  ║                                   ║
  ║                                   ║
  ║                                   ║
  ╚═══════════════════════════════════╝
*/
@media screen and (min-width: 980px) {
  .portal-section {
    margin: 50px 5%;
    width: auto;
    display: flex; }
    .portal-section .portal-section-title {
      border-top: 5px solid #e7e9ec;
      width: 30%;
      margin-right: 26px;
      margin-top: 0;
      padding-top: 0.6875em;
      font-size: 36px; }
      .portal-section .portal-section-title .extra-items {
        display: inline-block;
        margin-left: 1em;
        font-size: 14px; }
    .portal-section .portal-section-title-action {
      appearance: none;
      border: none;
      background: none;
      font-weight: 600;
      text-decoration: underline;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: #ccd0d6;
      outline: none; }
    .portal-section .portal-section-content {
      width: calc(70% - 26px); }
    .portal-section.full-width {
      width: auto; }
      .portal-section.full-width .portal-section-title {
        width: 30%;
        margin-right: 26px;
        margin-top: 0; } }

/*
  ╔════╤══════════════════════════╤═══╗
  ║••• │                          │ ? ║
  ║────┴──────────────────────────┴───║
  ║                                   ║
  ║                                   ║
  ║       Screen Size : l             ║
  ║                                   ║
  ║                                   ║
  ║                                   ║
  ╚═══════════════════════════════════╝
*/
@media screen and (min-width: 1200px) {
  .portal-section .portal-section-title {
    font-size: 42px; } }

/*
  ╔════╤══════════════════════════╤═══╗
  ║••• │                          │ ? ║
  ║────┴──────────────────────────┴───║
  ║                                   ║
  ║                                   ║
  ║       Screen Size : xl            ║
  ║                                   ║
  ║                                   ║
  ║                                   ║
  ╚═══════════════════════════════════╝
*/
@media screen and (min-width: 1400px) {
  .portal-section {
    max-width: 1267.2px;
    margin-left: auto;
    margin-right: auto; }
    .portal-section .portal-section-title {
      font-size: 48px; } }

/*
  ╔════╤══════════════════════════╤═══╗
  ║••• │                          │ ? ║
  ║────┴──────────────────────────┴───║
  ║                                   ║
  ║                                   ║
  ║       Screen Size : xxl           ║
  ║                                   ║
  ║                                   ║
  ║                                   ║
  ╚═══════════════════════════════════╝
*/
#select-language {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease-in-out; }
  #select-language:hover {
    background-color: rgba(0, 0, 0, 0.08); }
  #select-language .flag-container {
    width: 24px;
    height: 18px; }
  #select-language .flag-selector-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px; }
    #select-language .flag-selector-label p {
      line-height: 16px;
      font-weight: 400;
      color: #3d3d42;
      font-size: 12px; }
