/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.modal-content[data-name='global-error'] {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 630px;
  top: calc(50% - 490px/2);
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: white; }
  .modal-content[data-name='global-error'] .error-logo {
    display: block;
    width: 160px;
    margin: 0 0 30px 0; }
  .modal-content[data-name='global-error'] .modal-header {
    position: relative;
    height: 75px;
    line-height: 75px;
    padding: 0 40px;
    background-color: #f2f4f6; }
    .modal-content[data-name='global-error'] .modal-header p {
      font-size: 20px;
      font-weight: 600; }
    .modal-content[data-name='global-error'] .modal-header .modal-header-close {
      position: absolute;
      top: 30px;
      right: 40px;
      width: 16px;
      height: 16px;
      cursor: pointer; }
      .modal-content[data-name='global-error'] .modal-header .modal-header-close > .icon {
        display: block;
        color: #3d3d42; }
  .modal-content[data-name='global-error'] .modal-body {
    padding: 40px; }
    .modal-content[data-name='global-error'] .modal-body p {
      font-size: 14px;
      font-weight: 600; }
