@import 'scss/base/utils';

@include max-width(m) {
  .search-news-modal-container {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .header {
      padding: 24px 16px;
    }

    .content {
      padding: 24px 16px 0 16px;
    }

    .search-block {
      flex-direction: column;
    }

    .results-total {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;

      .toggle-container {
        width: 100%;
      }
    }

    .results-container {
      gap: 24px;
    }
    .result {
      margin-right: 16px;
    }

    .result {
      &:last-child {
        padding-bottom: 16px;
      }
    }

    .search-loader-block {
      .search-loader-container {
        width: 20%;
        height: 20%;
      }
    }

    .pagination {
      padding-bottom: 16px;
    }
  }
}
