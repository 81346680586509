/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.contact-search-results-row-container {
  display: flex;
  align-items: center;
  height: 52px;
  padding: 10px 12px;
  gap: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out; }
  .contact-search-results-row-container:hover {
    background-color: rgba(0, 0, 0, 0.08); }
  .contact-search-results-row-container .contact-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #929292;
    color: #3e3d42; }
    .contact-search-results-row-container .contact-picture img {
      width: 100%;
      height: 100%; }
    .contact-search-results-row-container .contact-picture svg {
      width: 50%;
      height: 50%; }
  .contact-search-results-row-container .contact-infos {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    color: #3e3d42;
    overflow: hidden; }
  .contact-search-results-row-container .contact-name {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700; }
    .contact-search-results-row-container .contact-name:only-child {
      margin: auto 0; }
  .contact-search-results-row-container .contact-job {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400; }
