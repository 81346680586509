/*

  Font weights, according to Mozilla's recommendations :

  100 : Thin
  200 : Extra Light
  300 : Light
  400 : Regular
  500 : Medium
  600 : Semi-Bold
  700 : Bold
  800 : Black

*/
.toolkit-modal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white; }
  .toolkit-modal-container .toolkit-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 21px 24px 16px;
    gap: 15px;
    border-bottom: 1px solid #e5e5e5; }
  .toolkit-modal-container .toolkit-modal-title {
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #3e3d42;
    text-transform: uppercase; }
  .toolkit-modal-container .toolkit-modal-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 16px 24px 16px;
    gap: 16px; }
