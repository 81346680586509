.site-footer-container {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 1.5rem; }
  @media (max-width: 768px) {
    .site-footer-container {
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 100px; } }
