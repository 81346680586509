.tool-row {
  padding: 28px;
  display: flex;
  background-color: white;
  transition: background-color 150ms ease;
  border-bottom: 1px solid #e9ebee; }
  .tool-row .tool-tile {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 12px; }
    .tool-row .tool-tile .icon {
      width: 20px;
      height: 20px; }
  .tool-row .tool-details {
    flex-grow: 1;
    padding: 0 1em;
    font-size: 14px;
    text-align: left; }
  .tool-row .tool-title {
    text-transform: uppercase;
    margin: 0.25em 0;
    color: #3D3D42; }
  .tool-row .tool-subtitle {
    margin: 0;
    color: #CCD0D6; }
  .tool-row .tool-button {
    width: 45px;
    height: 45px;
    background-color: #3D3D42;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .tool-row .tool-button .icon {
      width: 45%;
      height: 45%; }
